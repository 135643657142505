import { getURLParam } from './helpers';
import { loginRedirect, checkLogin } from './login';


Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

// Document ready equivalent
// Function is executed when the whole web page loads
$(() => {
  if (getURLParam('auth-redirect') == '1') {
    loginRedirect();
    return;
  }

  init();
});

const init = async () => {
  try {
    let userString = localStorage.getItem('user');
    if (userString) {
      await checkLogin(JSON.parse(userString)).catch(error => {
        if (error.message === 'VERIFY_UNAUTHORIZED') {
          localStorage.removeItem('user');
        }
      });
    }

    console.debug('main initialized')
  } catch (error) {
    console.error('main initialization error:', error);
  }
}

$(document).on('input', '.laptime-input', event => {
  let el = $(event.target);
  let input = el.val().
    replace(/\D/g, '').
    replace(/^0*/g, '');
  input = input.padStart(6, '_');
  let formattedInput = '_:__:___';

  let offset = 0;
  if (input.length > 6) {
    offset = 1;
  }
  formattedInput = input.substring(0, 1+offset) + ":" + input.substring(1+offset, 3+offset) + ":" + input.substring(3+offset);

  el.val(formattedInput);
});

